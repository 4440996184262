import axios from '../util/request'
import base from './base'
const http = {
    getTop1From: function (id) {
        return axios.get(base.getTop1From + '?id=' + id)
    },
    getPkCustomer: function (id) {
        return axios.get(base.getPkCustomer + '?id=' + id)
    },
    getSPOrderSelect: function (id) {
        return axios.get(base.getSPOrderSelect + '?id=' + id)
    },
    getSumItemQuantity: function (id) {
        return axios.get(base.getSumItemQuantity + '?id=' + id)
    },
    // http://localhost:8080/pk/obtainAllDishes?pageNo=?&pageSize=?&id=?
    obtainAllDishes: function (params) {
        return axios.get(base.obtainAllDishes + '?id=' + params.id + '&pageNo=' + params.pageNo + '&pageSize=' + params.pageSize)
    },
    savePKCustomerManagementDeliveryForecastXItem:function(params){
        return axios.post(base.savePKCustomerManagementDeliveryForecastXItem,{params})
    },
    updateOrInsertOrDrop:function(params){
        return axios.post(base.updateOrInsertOrDrop,params)
    },
    updatePKCustomerManagementDelivery:function(params){
        return axios.post(base.updatePKCustomerManagementDelivery,params)
    },
    updatePKCustomerManagementDeliveryForecastXItem:function(params){
        return axios.post(base.updatePKCustomerManagementDeliveryForecastXItem,params)
    },
    getCompositionOfAllMeals:function(){
        return axios.get(base.getCompositionOfAllMeals)
    },
    modifyRatin:function(params){
        return axios.post(base.modifyRatin,params)
    },
    quickAdd:function(params){
        return axios.post(base.quickAdd,params)
    },
    queryUser:function(params){
        return axios.post(base.queryUser,params)
    },
    updateCustomerWhereinPassword:function(params){
        //  userName:"",password:""
        return axios.post(base.updateCustomerWhereinPassword,params)
    },

    toLogin:function(params){
        //  userName:"",password:""
        return axios.post(base.toLogin,params)
    },
    toLoginEs:function(params){
        //  userName:"",password:""
        return axios.post(base.toLoginEs,params)
    },
    saveEditEmailXX:function (params){
        return axios.put(base.saveEditEmailXX,params)
    },
    getUserInfo:function(params){
        //  userName:""
        return axios.post(base.getUserInfo,params)
    },
    updateUserlnfo:function(params){
        // ("userName":"92455042A","cusEmail"""cusphoneH" :"530-501-6475","cusPhoneM":")
        return axios.post(base.updateUserlnfo,params)
    },
    sendCodeInfo:function(params){
        // {"cusPhoneM":"(135)2 49479- 96[NBSP]"}
        return axios.post(base.sendCodeInfo,params)
    },
    contrastCode:function(params){
        return axios.post(base.contrastCode,params)
    },
    updaeCallUpdate:function(params){
        return axios.post(base.updaeCallUpdate,params)
    },
    selectDietTypes:function(id){
        return axios.get(base.selectDietTypes +id)
    },
    callSpPrefsView:function(id){
        return axios.get(base.callSpPrefsView +id)
    },
    SelectSPCustIngredView:function(id){
        return axios.get(base.SelectSPCustIngredView +id)
    },
    updateSPCustIngredEdit:function(params){
        return axios.post(base.updateSPCustIngredEdit,params)
    },

    selectOrdersView: function(id){
        return axios.get(base.selectOrdersView +id)
    },
    selectOrderSelect: function(id){
        return axios.get(base.selectOrderSelect +'?id='+id)
    },
    getBenPrograms: function(id){
        return axios.get(base.getBenPrograms +'?id='+id)
    },
    selectShowEvaluation: function(params){
        return axios.get(base.selectShowEvaluation +'?itemId='+params.itemId +'&orderId='+params.orderId);
    },
    updatePkOrderItem:function(params){
        return axios.put(base.updatePkOrderItem,params);
    },
    getOrderBetweenTime:function(id){
        return  axios.get(base.getOrderBetweenTime+'?id='+id)
    },
    updatePkOrderTime:function(params){
        return axios.put(base.updatePkOrderTime,params);
    },
    getSumOriQty:function (params){
        return axios.get(base.getSumOriQty+'?itemId='+ params.itemId +'&ordId='+ params.ordId);
    },
    getSPMealSelect:function (id,Dinner,Breakfast,Lunch,ShowAll,rawMaterial){
        return axios.get(base.getSPMealSelect +'?id='+id+'&Dinner='+Dinner+'&Breakfast='+Breakfast+'&Lunch='+Lunch+'&ShowAll='+ShowAll+"&rawMaterial="+rawMaterial )
    },
    selectSum:function (id){
        return axios.get(base.selectSum + '?id='+id)
    },
    selectCountMealSelect:function (id){
        return axios.get(base.selectCountMealSelect + '?cusId='+id)
    },
    getPkCustomerFromCusKey:function (id){
        return axios.get(base.getPkCustomerFromCusKey + '?cusKey='+id)
    },
    deleteItem:function (params){
        return axios.delete(base.deleteItem +'?oriItmId='+ params.itemId +'&oriOrdId=' + params.orderId);
    },
    addPkItem:function (params){
        return axios.post(base.addPkItem,params);
    },
    addPkItemNew:function (params){
        return axios.post(base.addPkItemNew,params);
    },
    updatePkOrderItemMinus:function (params){
        return  axios.put(base.updatePkOrderItemMinus,params);
    },
    routineNewAndReduceDishes:function (params){
        return  axios.post(base.routineNewAndReduceDishes,params);
    },
    updatePkOrderItemAdd:function (params){
        return axios.put(base.updatePkOrderItemAdd,params);
    },
    selectOrdersViews:function (id){
    return axios.get(base.selectOrdersViews+id)
    },
    sPAuthStatus:function (params){
        return axios.post(base.sPAuthStatus,params)
    },
    selelctPkCancellationReason:function (params){
      return axios.get(base.selelctPkCancellationReason,params)
    },
    SendUserNameToEmail:function (params){
      return axios.post(base.SendUserNameToEmail,params)
    },
    getSPMealSelectOne:function (id,itemId){
        return axios.get(base.getSPMealSelectOne + '?oriOrdId='+id+'&itemId='+itemId)
    },

    getAuthView:function (oId){
        return axios.get(base.getAuthView + '?oId='+oId)
    },

    upStatus:function (oId,user){
        return axios.get(base.upStatus + '?oId='+oId+"&user="+user)
    },
    getCustViewForCid:function (id){
        return axios.get(base.getCustViewForCid + '?id='+id)
    },

    ExecCusStatus:function (userName,cusKey){
        return axios.get(base.ExecCusStatus+"?cusKey="+cusKey+"&userName="+userName)
    },

    sendCodeInfoForRegister:function(params){
        // {"cusPhoneM":"(135)2 49479- 96[NBSP]"}
        return axios.post(base.sendCodeInfoForRegister,params)
    },

    getCallCustView:function (cusKey){
        return axios.get(base.getCallCustView+"?cusKey="+cusKey)
    },
    getSurveyPending:function (cusKey){
        return axios.get(base.getSurveyPending+"?cKey="+cusKey)
    },
    validateToken:function (token){
        return axios.get(base.validateToken+"?token="+token)
    },
    getModifierList:function (){
        return axios.get(base.getModifierList)
    },
    getSpPrefsView:function (cusId){
        return axios.get(base.getSpPrefsView+"?cusId="+cusId)
    }

}
export default http
